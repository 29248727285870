<!--  -->
<template>
	<div class="app-container">
		<div class="search_box">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item class="formLabel" label="部门">
					<!--<treeselect
            :props="depProps"
            :options="deps"
            :value="searchForm.department"
            :clearable="true"
            :accordion="true"
            :level="searchForm.department"
            @getValue="_getValue($event)" />!-->
					<el-input v-model="searchForm.departmentName" size="small" placeholder="请输入部门名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位">
					<el-input v-model="searchForm.jobName" size="small" placeholder="请输入岗位名称"></el-input>
					<!--<el-select
            placeholder="请选择岗位"
            style="width:100%;"
            filterable
            clearable
            size="small"
            v-model="searchForm.jobName"
          >
            <el-option
              v-for="item in jobList"
              :key="item.id"
              :label="item.jobOrOfficeName"
              :value="item.id"
            ></el-option>
          </el-select>!-->
				</el-form-item>
				<el-form-item class="formLabel" label="姓名">
					<el-input v-model="searchForm.staffName" size="small" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="手机号">
					<el-input v-model="searchForm.phone" size="small" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="创建时间" style="margin-right: 0">
					<el-date-picker
						type="date"
						size="small"
						value-format="yyyy-MM-dd"
						:clearable="false"
						placeholder="选择开始时间"
						:picker-options="pickerOptionsStart2"
						v-model="searchForm.createStartTime"
						style="width: 150px"
					></el-date-picker>

					<span style="padding: 0 3px">-</span>
					<el-date-picker
						type="date"
						:picker-options="pickerOptionsStart2"
						value-format="yyyy-MM-dd"
						placeholder="选择结束时间"
						size="small"
						:clearable="false"
						v-model="searchForm.createEndTime"
						style="width: 150px"
					></el-date-picker>
				</el-form-item>
				<!--<el-form-item label-width="0">
        </el-form-item>!-->
				<el-form-item class="fr">
					<el-button type="primary" @click="query" size="small" style="margin-left: 20px" icon="el-icon-search">查询</el-button>
					<el-button
						type="primary"
						@click="exportData('0')"
						size="small"
						icon="el-icon-notebook-1"
						style="margin-left: 15px"
						v-if="permissionControlBtns(pageName, 'ImportAddressBook')"
						>导入通讯录</el-button
					>
					<el-button
						type="primary"
						@click="exportData('1')"
						icon="el-icon-upload"
						size="small"
						style="margin-left: 15px"
						v-if="permissionControlBtns(pageName, 'ImportOriginalData')"
						>导入原始数据</el-button
					>
					<el-button
						type="primary"
						@click="toDingDing"
						icon="el-icon-office-building"
						size="small"
						style="margin-left: 15px"
						v-if="permissionControlBtns(pageName, 'ToDing')"
						>进入钉钉平台</el-button
					>
				</el-form-item>
			</el-form>
			<el-table :data="tableList" v-loading="loadding" style="width: 100%" height="600px" header-row-class-name="tableClass">
				<el-table-column type="index" label="序号" :width="50" :index="indexMethod"></el-table-column>
				<el-table-column label="姓名" align="center" prop="staffName"></el-table-column>
				<el-table-column label="工号" align="center" prop="workNumber"></el-table-column>
				<el-table-column label="考勤组" align="center" prop="attendanceGroup"></el-table-column>
				<el-table-column label="部门" align="center" prop="departmentName"></el-table-column>
				<el-table-column label="岗位" align="center" prop="job"></el-table-column>
				<el-table-column label="考勤日期" align="center" prop="date">
					<template slot-scope="scope"> {{ timestampToTime(scope.row.date, true) }} 周{{ scope.row.weeks }} </template>
				</el-table-column>
				<el-table-column label="上班考勤时间" align="center" prop="workAmTime" min-width="115"></el-table-column>
				<el-table-column label="下班考勤时间" align="center" prop="workPmTime" min-width="115"></el-table-column>
				<el-table-column label="打卡时间" align="center" prop="clockAmTime">
					<template slot-scope="scope">
						<p>{{ scope.row.clockAmTime }}</p>
						<p>{{ scope.row.clockPmTime }}</p>
					</template>
				</el-table-column>
				<el-table-column label="打卡结果" align="center" prop="attendance">
					<template slot-scope="scope">
						<p :style="`color:${returnColor(scope.row.attendanceAm)}`">{{ scope.row.attendanceAm }}</p>
						<p :style="`color:${returnColor(scope.row.attendancePm)}`">{{ scope.row.attendancePm }}</p>
					</template>
				</el-table-column>
				<el-table-column label="打卡地址" align="center" prop="workAmPlace"></el-table-column>
				<el-table-column label="打卡设备" align="center" prop="punchAmEquipment"></el-table-column>
			</el-table>

			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="table.pageNo"
				:page-sizes="[20, 50, 100, 200]"
				:page-size="table.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="table.total"
			></el-pagination>
		</div>
		<importmodal
			:importUrl="importUrl"
			:Template="false"
			:type="type"
			:width="'40%'"
			@handleSearch="query"
			:dialogImportVisible.sync="dialogImportVisible"
		></importmodal>
	</div>
</template>

<script>
import importmodal from '../../components/import';
import { getOriginalList } from '../../api/time';
import { timestampToTime } from '../../utils/util';
// import treeselect from "@/components/treeSelect/treeSelect";
export default {
	props: { pageName: {} },
	components: { importmodal },
	data() {
		return {
			loadding: false,
			dialogImportVisible: false,
			importUrl: '',
			type: 'time',
			searchForm: {
				departmentName: '',
				jobName: '',
				staffName: '',
				staffId: '',
				phone: '',
				Month: '',
				createStartTime: new Date(new Date().setMonth(new Date().getMonth() - 1)),
				createEndTime: new Date()
			},
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			table: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			pickerOptionsStart2: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			jobList: [],
			tableList: [],
			departmentList: [] // 部门
		};
	},
	computed: {
		dict() {
			return this.$store.state.app.dict;
		},
		dictData() {
			const Arr = {
				sexList: [],
				sorceList: [],
				experiencelist: [],
				educationList: []
			};

			Array.isArray(this.dict) &&
				this.dict.forEach((item) => {
					if (item.groupId === 'sex') {
						Arr.sexList.push({ value: item.dictId, label: item.dictName });
					}
				});
			return Arr;
		}
	},
	mounted() {
		this.getJobList();
		this.getDepartmentList();
		this.OriginalList();
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.table.pageNo - 1) * this.table.pageSize + 1;
		},
		returnColor(result) {
			if (result) {
				if (result == '正常') {
					return '#00C648';
				}
				if (result == '下班缺卡' || result == '上班缺卡' || result == '旷工') {
					return '#FF0000';
				}
				if (result == '休息' || result == '休息并打卡') {
					return '#40CEFC';
				}
				if (result == '迟到' || result == '早退') {
					return '#FF7800';
				}
				if (result == '补签') {
					return '#FFBC13';
				}
				if (result == '请假') {
					return '#409EFF';
				}
			}
		},
		timestampToTime,
		_getValue(eve) {
			this.searchForm.department = eve.id;
		},
		query() {
			this.table.pageNo = 1;
			this.OriginalList();
		},
		OriginalList() {
			this.loadding = true;
			getOriginalList({
				...this.searchForm,
				createStartTime:
					typeof this.searchForm.createStartTime === 'string'
						? this.searchForm.createStartTime
						: timestampToTime(this.searchForm.createStartTime.getTime(), true),
				createEndTime:
					typeof this.searchForm.createEndTime === 'string'
						? this.searchForm.createEndTime
						: timestampToTime(this.searchForm.createEndTime.getTime(), true),
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			}).then((res) => {
				if (res.list) {
					res.list.map((it) => {
						switch (new Date(it.date).getDay()) {
							case 0: {
								it.weeks = '日';
								break;
							}
							case 1: {
								it.weeks = '一';
								break;
							}
							case 2: {
								it.weeks = '二';
								break;
							}
							case 3: {
								it.weeks = '三';
								break;
							}
							case 4: {
								it.weeks = '四';
								break;
							}
							case 5: {
								it.weeks = '五';
								break;
							}
							case 6: {
								it.weeks = '六';
								break;
							}
						}
					});
					this.tableList = res.list;

					new Date('2020-05-08').getDay();
					this.loadding = false;
					this.table.total = res.totalNum;
				}
			});
		},
		exportData(id) {
			this.$confirm(`导入会覆盖之前的员工数据,是否确定导入${id == '0' ? '通讯录' : '原始数据'}?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if (id == '0') {
					this.importUrl = '/v1/attendance/addressbook/import';
				} else {
					this.importUrl = '/v1/attendance/originaldata/import';
				}
				this.dialogImportVisible = true;
			});
		},
		// 进入钉钉平台
		toDingDing() {
			window.open('https://attend.dingtalk.com/portal/index.html');
		},
		getJobList() {
			this.$axios
				.post('/v1/org/queryOfficeJob/list', {
					request: {
						type: '2', // 1：职位 2：岗位
						name: '',
						pageNo: 1,
						pageSize: 20000
					},
					sign: ''
				})
				.then((res) => {
					this.jobList = res.list;
				});
		},
		// 获取部门
		getDepartmentList() {
			this.$axios
				.post('/v1/org/queryStaffJob/list', {
					request: {},
					sign: ''
				})
				.then((res) => {
					this.departmentList = res;
				});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.OriginalList();
		},

		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.OriginalList();
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 20px;
}
.search_box {
	margin: 10px 0;
}
</style>
<style lang="scss">
.formLabel {
	.el-form-item__label {
		font-weight: normal;
	}
}
</style>
